let agencias = [
    {
        id: 396,
        name: "20 de Setembro",
        cooperativa: 5,
    },
    {
        id: 437,
        name: "Ana Rech",
        cooperativa: 5,
    },
    {
        id: 434,
        name: "Cruzeiro",
        cooperativa: 5,
    },
    {
        id: 451,
        name: "Desvio Rizzo",
        cooperativa: 5,
    },
    {
        id: 4435,
        name: "Forqueta",
        cooperativa: 5,
    },
    {
        id: 4429,
        name: "Galópolis",
        cooperativa: 5,
    },
    {
        id: 439,
        name: "Lourdes",
        cooperativa: 5,
    },
    {
        id: 150,
        name: "Pio X",
        cooperativa: 5,
    },
    {
        id: 405,
        name: "Rio Branco",
        cooperativa: 5,
    },
    {
        id: 444,
        name: "Lúcia do Piai",
        cooperativa: 5,
    },
    {
        id: 4404,
        name: "Santa Lúcia",
        cooperativa: 5,
    },
    {
        id: 4399,
        name: "Sanvitto",
        cooperativa: 5,
    },
    {
        id: 446,
        name: "Vila Cristina",
        cooperativa: 5,
    },
];

let agenciasB = [
    {
        id: 3136,
        name: "Porto Alegre",
        cooperativa: 7,
    },
];

let agenciasC = [
    {
        id: 4411,
        name: "Caxias do Sul",
        cooperativa: 12,
    },
];

const numParcelasFugentur = ["36x", "48x", "60x"];
const numParcelasSicrediECresol = ["36x", "48x", "60x", "72x", "84x", "96x", "120x"];

export { agencias, agenciasB, agenciasC, numParcelasFugentur, numParcelasSicrediECresol };
