import React from "react";
import logoUm from "../assets/imgs/badesul25.PNG";
import riograndedosul from "../assets/imgs/riograndedosul.png";
import ministeriodoturismobrasil from "../assets/imgs/ministeriodoturismobrasil.png";
import sicredi from "../assets/imgs/sicredi.png";
import sebrae from "../assets/imgs/sebrae.png";
import cresol from "../assets/imgs/cresol.png";
import rsgaranti from "../assets/imgs/rsgaranti.png";
import prefeituradecaxiasdosul from "../assets/imgs/prefeituradecaxiasdosul.png";

const CcxFooter = () => {
  return (
    <section id="footer">
      <div className="container">
        <div className="CcxFooter">
          <div className="logos">
            <a
              href="https://www.badesul.com.br/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img src={logoUm} alt="Logo da Badesul" />
            </a>
            <a
              href="https://www.rs.gov.br/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img
                src={riograndedosul}
                alt="Logo do DO Estado do Rio Grande do Sul"
              />
            </a>
            <a
              href="https://www.gov.br/turismo/pt-br"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img src={ministeriodoturismobrasil} alt="Logo do Ministério do Turismo" />
            </a>
            <a
              href="https://sicredipioneira.com.br/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img src={sicredi} alt="Logo da Sicredi Pioneira" />
            </a>
            <a
              href="https://sebraers.com.br/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img src={sebrae} alt="Logo do SEBRAE" />
            </a>
            <a
              href="https://cresol.com.br/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img src={cresol} alt="Logo da CRESOL" />
            </a>
            <a
              href="https://www.rsgaranti.org.br/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img src={rsgaranti} alt="Logo da RS Garanti" />
            </a>
            <a
              href="https://caxias.rs.gov.br/"
              target={"_blank"}
              rel="noopener noreferrer"
            >
              {" "}
              <img src={prefeituradecaxiasdosul} alt="Logo da Prefeitura de Caxias do Sul" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CcxFooter;
