import React from "react";
import Pessoa1 from "../assets/imgs/pessoa-1.png";
import Pessoa2 from "../assets/imgs/pessoa-2.png";
import Pessoa3 from "../assets/imgs/pessoa-3.png";
import Aviso from "../assets/imgs/ico-8.png";

const BcxSectionE = () => {
  return (
    <section id="sectionE">
      <div className="container">
        <div className="BcxSectionE">
          <h2 className="title">
            Quem pode ter acesso
            <br /> <span>ao Credi Turismo Caxias</span>
          </h2>

          <div className="micInd rowCard">
            <div className="imagem">
              <img src={Pessoa1} alt="Entrada da cidade de Dois Irmãos" />
            </div>

            <div className="texto">
              <h3>
                <span>Microempreendedor</span>
                <br />
                Individual
              </h3>
              <p>
                Com receita bruta anual igual <br /> ou inferior a R$ 81 mil
              </p>
              <p>
                <span style={{color:"white"}}>Limite de crédito:</span>
                <br />
                <span className="sicrediGreen">
                Linha Sicredi Pioneira Investimento em Turismo:
                </span>
                <br />
                R$ 15 mil em 36 parcelas (sem carência) de até R$ 560,00
                <br />
                <span style={{color: "white"}}>Custo da garantia:</span>
                <br />
                R$ 993,60
              </p>
              <p></p>
            </div>
          </div>

          <div className="micEmp rowCard">
            <div className="imagem">
              <img src={Pessoa2} alt="Escadaria Parque Romeo Benicio Wolf" />
            </div>

            <div className="texto">
              <h3>Microempresa</h3>
              <p>
                Com receita bruta anual igual <br /> ou inferior a R$ 360 mil
              </p>
              <p>
                <span style={{color:"white", fontSize: "25px"}}>Limite de crédito de até R$ 50 mil</span>
                <br />
                <span className="parcelas">
                   em 36 parcelas (sem carência):
                </span>
                <br />
                <span className="fungetur">Linha Fungetur:</span> até R$
                1.623,70
                <br />
                <span className="cresolYellow">Linha Cresol Turismo:</span> até R$ 
                1.712,76
                <br />
                <span className="sicrediGreen">
                Linha Sicredi Pioneira Investimento em Turismo: 
                </span>{" "}
                até R$ 1.712,76
                <br />
                <br />
                <span className="parcelas">
                   em 48 parcelas (sem carência):
                </span>
                <br />
                <span className="fungetur">Linha Fungetur:</span> até R$
                1.278,72
                <br />
                <span className="cresolYellow">
                Linha Cresol Turismo:
                </span>{" "}
                até R$ 1.370,34
                <br />
                <span className="sicrediGreen">
                Linha Sicredi Pioneira Investimento em Turismo:
                </span>{" "}
                até R$ 1.370,34
                <br />
                <br />
                <span className="parcelas">
                   em 60 parcelas (sem carência):
                </span>
                <br />
                <span className="fungetur">Linha Fungetur:</span> até R$
                1.073,20
                <br />
                <span className="cresolYellow">
                Linha Cresol Turismo:
                </span>{" "}
                até R$ 1.167,56
                <br />
                <span className="sicrediGreen">
                Linha Sicredi Pioneira Investimento em Turismo:
                </span>{" "}
                até R$ 1.167,56
                <br />
                <br />
                <span style={{color: "white"}}>Custo da garantia:</span>
                <br />
                R$ 3.312,00 (36 meses)
                <br />
                R$ 4.416,00 (48 meses)
                <br />
                R$ 5.520,00 (60 meses)
              </p>
            </div>
          </div>

          <div className="peqEmp rowCard">
            <div className="imagem">
              <img
                src={Pessoa3}
                alt="Prédio centenário da antiga Igreja Matriz de São Miguel."
              />
            </div>

            <div className="texto">
              <h3>
                Pequenas
                <br />
                <span>Empresas</span>
              </h3>
              <p>
                Com receita bruta anual igual <br /> ou inferior a R$ 4,8
                milhões
              </p>
              <p>
                <span style={{color: "white"}}>Limite de crédito de até R$ 200 mil</span>
                <br />
                <span className="parcelas">
                   em 36 parcelas (sem carência):
                </span>
                <br />
                <span className="fungetur">Linha Fungetur:</span> até R$
                6.494.83
                <br />
                <span className="cresolYellow">
                Linha Cresol Turismo:
                </span>{" "}
                até R$ 6.851,07
                <br />
                <span className="sicrediGreen">
                Linha Sicredi Pioneira Investimento em Turismo:
                </span>{" "}
                até R$ 6.851,07
                <br />
                <br />
                <span className="parcelas">
                   em 48 parcelas (sem carência):
                </span>
                <br />
                <span className="fungetur">Linha Fungetur:</span> até R$
                5.114,88
                <br />
                <span className="cresolYellow">
                Linha Cresol Turismo:
                </span>{" "}
                até R$ 5.481,36
                <br />
                <span className="sicrediGreen">
                Linha Sicredi Pioneira Investimento em Turismo:
                </span>{" "}
                até R$ 5.481,36
                <br />
                <br />
                <span className="parcelas">
                   em 60 parcelas (sem carência):
                </span>
                <br />
                <span className="fungetur">Linha Fungetur:</span> até R$
                4.292,83
                <br />
                <span className="cresolYellow">
                Linha Cresol Turismo:
                </span>{" "}
                até R$ 4.670,25
                <br />
                <span className="sicrediGreen">
                Linha Sicredi Pioneira Investimento em Turismo:
                </span>{" "}
                até R$ 4.670,25
                <br />
                <br />
                <span style={{color: "white"}}>Custo da garantia:</span>
                <br />
                R$ 13.248,00 (36 meses)
                <br />
                R$ 17.664,00 (48 meses)
                <br />
                R$ 22.080,00 (60 meses)
              </p>
            </div>
          </div>

          <div className="aviso">
            <div className="imgAviso">
              <img
                src={Aviso}
                alt="Icone, formato Balão, com Exclamação dentro"
              />
            </div>

            <div className="textoAviso">
              <p>
                • Crédito sujeito a análise de crédito. Parcelas SIMULADAS com
                valores APROXIMADOS sem IOF.
                <br />• Terão acesso à linha associados que apresentarem no
                mínimo 01 ano de faturamento completo, declarados nos documentos
                indicados na documentação necessária abaixo.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionE;
