import React from "react";
import "./assets/styles/styles.css";
import "./assets/styles/mediaqueries.css";
import "./assets/styles/form.css";
import AcxHeader from "./components/AcxHeader";
import BcxSectionA from "./components/BcxSectionA";
import BcxSectionB from "./components/BcxSectionB";
import BcxSectionC from "./components/BcxSectionC";
import BcxSectionD from "./components/BcxSectionD";
import BcxSectionE from "./components/BcxSectionE";
import BcxSectionF from "./components/BcxSectionF";
import BcxSectionForm from "./components/BcxSectionForm";
import BcxSectionG from "./components/BcxSectionG";
import CcxFooter from "./components/CcxFooter";
import BcxSectionTurismo from "./components/BcxSectionTurismo";

function App() {
  return (
    <div className="App">
      <AcxHeader />
      <BcxSectionA />
      <BcxSectionTurismo />
      <BcxSectionB />
      <BcxSectionC />
      <BcxSectionD />
      <BcxSectionE />
      <BcxSectionF />
      <BcxSectionForm />
      <BcxSectionG />
      <CcxFooter />
    </div>
  );
}

export default App;
