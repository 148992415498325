import React from "react";
import Sebrae from "../assets/imgs/sebrae_sem_fundo.png";

const BcxSectionD = () => {
  return (
    <section id="sectionD">
      <div className="container">
        <div className="BcxSectionD">
          <div className="descricao">
            <div className="title">
              <img src={Sebrae} alt="Logo da Sebrae" />
              <h2>
                Uma Parceria <br />
                <span>
                  com soluções para
                  <br />
                  sua empresa
                </span>
              </h2>
            </div>
            <p>
              O Sebrae está oferecendo 10 capacitações{" "}
              <strong>GRATUITAS</strong> on-line aos interessados.
            </p>
            <p>
              Mesmo sem a efetivação do crédito, você pode ter acesso a
              ferramentas que te ajudam a gerenciar melhor seus negócios.
            </p>
            <p>
              <strong>Aproveite essa oportunidade!</strong>
            </p>
          </div>
          <div className="botoes">
            <div className="content">
              <h3>Finanças</h3>
              <a
                className="btn"
                href="https://sebrae.com.br/sites/PortalSebrae/cursoseeventos/avaliacao-da-gestao-na-propriedade-rural,7ec0b8a6a28bb610VgnVCM1000004c00210aRCRD"
                target="_blank"
                rel="noopener noreferrer"
              >
                Avaliação da gestão na propriedade rural
              </a>

              <h3>Planejamento</h3>
              <a
                className="btn"
                href="https://sebrae.com.br/sites/PortalSebrae/cursosonline/boas-praticas-como-diferencial-na-gestao-das-propriedades-rurais,f601b8a6a28bb610VgnVCM1000004c00210aRCRD"
                target="_blank"
                rel="noopener noreferrer"
              >
                Boas práticas como diferencial na gestão das propriedades rurais
              </a>

              <h3>Mercado e Vendas</h3>
              <a
                className="btn"
                href="https://sebrae.com.br/sites/PortalSebrae/cursosonline/a-gestao-da-propriedade-rural-por-um-novo-ponto-de-vista,4726d4e21eeeb710VgnVCM100000d701210aRCRD"
                target="_blank"
                rel="noopener noreferrer"
              >
                A gestão da propriedade rural por um novo ponto de vista
              </a>

              <h3>Leis</h3>
              <a
                className="btn"
                href="https://sebrae.com.br/sites/PortalSebrae/cursosonline/formalizacao-da-propriedade-rural-por-onde-comecar,76df60aa979ca710VgnVCM100000d701210aRCRD"
                target="_blank"
                rel="noopener noreferrer"
              >
                Formalização da propriedade rural: por onde começar?
              </a>

              <h3>Marketing Digital</h3>
              <a
                className="btn"
                href="https://www.sebrae.com.br/sites/PortalSebrae/cursosonline/marketing-digital-para-o-empreendedor,f870b8a6a28bb610VgnVCM1000004c00210aRCRD"
                target="_blank"
                rel="noopener noreferrer"
              >
                Marketing digital para o empreendedor
              </a>
            </div>
            <div className="content">
              <h3>Mercado e Vendas</h3>
              <a
                className="btn"
                href="https://sebrae.com.br/sites/PortalSebrae/cursosonline/atrativos-turisticos,10f0b8a6a28bb610VgnVCM1000004c00210aRCRD"
                target="_blank"
                rel="noopener noreferrer"
              >
                Atrativos turísticos
              </a>

              <h3>Gestão no Turismo</h3>
              <a
                className="btn"
                href="https://www.gov.br/pt-br/servicos/curso-gestor-de-turismo-cgt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Gestor de Turismo
              </a>

              <h3>Infográficos</h3>
              <a
                className="btn"
                href="https://sebraers.com.br/solucao-digital-detalhe/?idsolucao=12095&utm_source=blog&utm_medium=ref_botao&utm_campaign=&utm_content=_postblog_mapa_do_turista_gaucho"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mapa de Comportamento ajuda a qualificar o trade turístico
                gaúcho
              </a>

              <h3>Manual de Atendimento (PDF)</h3>
              <a
                className="btn"
                href="https://www.sebrae.com.br/Sebrae/Portal Sebrae/UFs/RN/Anexos/Gestao-e-Comercializacao-Manual-de-Atendimento-Individual-do-Agronegocio.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Manual de Atendimento do Agronegócio
              </a>

              <h3>Atendimento Sebrae</h3>
              <a
                className="btn"
                href="https://conhecimento.sebraers.com.br/lp/sebrae-e-pra-ti-turismo-bloqueada/?utm_source=google_ads&utm_medium=cpc&utm_campaign=sebraeprati_outbound&utm_content=sebraeprati_outbound_conversao_turismo_genericoInteresse_Tematica_TUR_infografico_&gclid=EAIaIQobChMIprD_w5SJgAMVXUFIAB0xEAzpEAAYASAAEgJuePD_BwE"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tire suas dúvidas no segmento do turismo
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionD;
