import React from "react";
import card4 from "../assets/imgs/ico-4.png";
import card5 from "../assets/imgs/ico-5.png";
import card6 from "../assets/imgs/ico-6.png";
import card7 from "../assets/imgs/ico-7.png";

const BcxSectionC = () => {
  return (
    <section id="sectionC">
      <div className="container">
        <div className="BcxSectionC">
          <h2 className="title">
            Condições e <span>taxas diferenciadas</span>
          </h2>
          <div className="cards">
            <div className="card">
              <img src={card4} alt="Icone Item 1" />
              <h3>
                <strong>
                  Taxa de Juros
                  <br />
                  Linhas:
                </strong>
                <br />
                <br />
                FUNGETUR: INPC + 5% a.a.
                <br />
                <br />
                <br />
                SICREDI PIONEIRA INVESTIMENTO EM TURISMO E CRESOL TURISMO: <br />
                CDI + 0,50% a.m.
                <br />
                <br />
              </h3>
            </div>
            <div className="card">
              <img src={card5} alt="Icone Item 2" />
              <h3>
                <strong>Prazo de pagamento:</strong>
                <br />
                <strong>Fungetur:</strong>
                <br />
                até 60 meses prazo total com até 12 meses de carência <br />
                (com pagamentos dos juros mensais)
              </h3>
              <h3>
                <strong>Sicredi Pioneira Investimento em Turismo e Cresol Turismo:</strong>
                <br />
                até 120 meses prazo total com até 12 meses de carência <br />
              </h3>
            </div>
            <div className="card">
              <img src={card6} alt="Icone Item 3" />
              <h3>
                Valores de até <strong>R$ 200 mil,</strong>
                <br />
                conforme porte da empresa
              </h3>
            </div>
            <div className="card">
              <img src={card7} alt="Icone Item 4" />
              <h3>
                <strong>Garantia</strong>
                <br />
                aval dos sócios e carta
                <br /> de garantia da RSGaranti
              </h3>
            </div>
          </div>
          <div className="rodape">
            <p>
              *Condições mediante disponibilidade e aprovação do crédito
              (capacidade de pagamento e sem restrições).
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionC;
