import React from "react";

const BcxSectionF = () => {
  function getDeclaratoryYear(){
    return (new Date().getFullYear() - 1)
  };
  return (
    <section id="sectionF">
      <div className="container">
        <div className="BcxSectionF">
          <div className="conteudo">
            <div className="title">
              <h2>
                <span>Documentação</span>
                <br />
                necessária
              </h2>
            </div>

            <div className="texto">
              <p>
                • RG, CPF e comprovante de endereço e renda dos sócios. Pessoas
                casadas ou com união estável devem apresentar também
                documentação do cônjuge. Cópias devem ser coloridas.
              </p>

              <div className="inscMunic">
                <p>
                  • Negativas de tributos:
                  <br />
                  CND FEDERAL, CND ESTADUAL, CND MUNICIPAL, CADASTUR, CERTIDÃO
                  FGTS, RECIBO DA RAIS, OU PRINT DA TELA DO E-SOCIAL.
                </p>
                <br />
                <div class="tabs">
                  <div class="tab">
                    <input type="checkbox" id="chck1" />
                    <label class="tab-label" for="chck1">
                      Links:
                    </label>
                    <div class="tab-content">
                      <ul>
                        <li>
                          <a
                            href="https://solucoes.receita.fazenda.gov.br/servicos/certidaointernet/pj/emitir"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            CND Federal
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.sefaz.rs.gov.br/sat/CertidaoSitFiscalSolic.aspx"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            CND Estadual
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://caxias.rs.gov.br/servicos/receita/certidao-negativa-de-debitos"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            CND Municipal
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://consulta-crf.caixa.gov.br/consultacrf/pages/consultaEmpregador.jsf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Certidão FGTS
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://cadastur.turismo.gov.br/hotsite/#!/public/sou-prestador/inicio"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            CADASTUR
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://www.rais.gov.br/sitio/recibo_identificacao.jsf"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Recibo da RAIS
                          </a>
                        </li>
                        <li>
                          <a
                            href="http://consultacadastral.inss.gov.br/Esocial/pages/index.xhtml"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            e-Social
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://forms.gle/jysqjMaib44Vpe8XA"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Alvará Turismo
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <p>
                <span>Mais documentos abaixo, conforme tipo de empresa:</span>
              </p>
              <p className="tipoEmpresa">
                <span>• Se empresa enquadrada como MEI:</span>
                <ul>
                  <li>
                    - CCMEI: Certificado de Condição de Microempreendedor
                    Individual.
                  </li>
                  <li>- DASN SIMEI: Declaração Anual do SIMEI.</li>
                </ul>
              </p>
              <p className="tipoEmpresa">
                <span>• Se empresa enquadrada como ME / EPP:</span>
                <ul>
                  <li>- Declaração de Firma Individual ou Contrato Social</li>
                  <li>
                    - Empresa do Simples Nacional: <br />
                    PGDAS-D. Programa Gerador do Documento de Arrecadação do
                    Simples Nacional Declaratório {getDeclaratoryYear()}, com faturamento dos
                    últimos 12 meses.
                  </li>

                  <li>
                    - Empresa do Lucro Presumido: <br />
                    SPED Contábil | Recibo, Balanço e DRE
                  </li>
                </ul>
              </p>
              <div className="asterisco">
                <p>
                  * A documentação completa será solicitada somente após análise
                  das informações fornecidas no formulário. Preencha com seus
                  dados e aguarde contato do Sicredi.
                </p>
                <p>
                  * Se você não é associado(a) da Sicredi Pioneira ou Cresol,
                  será necessária a abertura de uma conta corrente.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionF;
