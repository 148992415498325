import React from "react";

const BcxSectionG = () => {
  return (
    <section id="sectionG">
      <div className="container">
        <div className="BcxSectionG">
          <h2 className="title">
            <span>Dúvidas Frequentes</span> <br />
            Sobre o Programa
          </h2>

          <div className="duvida">
            <h3>
              Sou associado, posso ir até a minha agência solicitar o crédito?
            </h3>
            <p>
              Sim. Seu gerente irá esclarecer suas dúvidas e orientar o seu
              cadastramento no hotsite do programa.
            </p>
          </div>

          <div className="duvida">
            <h3>
              A Garantidora RSGaranti irá cobrar pela prestação da garantia?
            </h3>
            <p>
              Sim. Para saber mais, entre em contato com a RSGaranti através do
              telefone
              <br />
              (54) 3021-0400 ou pelo WatsApp (54) 99998-3822.
            </p>
          </div>

          <div className="duvida">
            <h3>Serão verificados restritivos?</h3>
            <p>
              Sim. A análise de crédito será feita normalmente junto as
              instituições financeiras e a RSGaranti.
            </p>
          </div>

          <div className="duvida">
            <h3>
              Para ter acesso ao crédito, preciso abrir uma conta corrente com a
              Sicredi Pioneira, a Cresol ou Badesul?
            </h3>
            <p>
              Sim. Se você optar em realizar a operação de crédito junto as
              cooperativas participantes Sicredi Pioneira ou Cresol será
              necessário abrir uma conta corrente e ser associar junto a
              cooperativa. Se optar em realizar pelo Badesul como é um banco de
              fomento não será necessário abrir conta corrente.
            </p>
          </div>
          <h4>
            Para maiores informações, entre e contato com a Secretaria de
            Turismo através do fone: (54) 3222.1875
          </h4>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionG;
