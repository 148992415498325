import React from "react";
import imgUm from "../assets/imgs/credTurismoQrCode.png";

const BcxSectionTurismo = () => {
  return (
    <section id="SectionTurismo">
      <div className="container">
        <div className="BcxSectionTurismo">
          <h2 className="title">
            Roteiros <span>Turísticos</span>
          </h2>
          <article className="cntdTurismo">
            <div className="imgs">
            <img
                  src={imgUm}
                  alt="Curta nossos roteiros, Bem-vindo a Caxias do Sul"
                  className="imgUm"
                />
            </div>

            <div className="text">
              <h3>As atrações de Caxias foram divididas em sete roteiros:</h3>
              <p>
                . La Città (Roteiro Urbano) engloba os principais pontos
                turísticos do Centro;
              </p>
              <p>
                . Vale Trentino reúne dez atrações, entre vinícolas,
                restaurantes, o Museu da Uva e do Vinho;
              </p>
              <p>
                . Estrada do Imigrante tem casas de madeira e de pedra do início
                da colonização, entre elas as Casas Bonnet;
              </p>
              <p>
                . Ana Rech, visita o Monumento Epopeia Imigrante , Castelo
                Chateau Lacave entre outras atrações;
              </p>

              <p>
                . Caminhos da Colônia estão vinícolas como Zanrosso e Tonet,
                além de restaurantes italianos;
              </p>
              <p>
                . Criúva está o Memorial Irmãos Bertussi, a Ponte do Korff, tem
                piscinas naturais, cascatas e trilhas.
              </p>
            </div>
          </article>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionTurismo;
