import React from "react";
import img1 from '../assets/imgs/img1.png';
import novaImagem from '../assets/imgs/novaImagem.png';

const BcxSectionA = () => {
  return (
    <section id="SectionA">
      <div className="container">
        <div className="BcxSectionA">
          <div className="text">
            <p>
              Caxias do Sul, localizada na região Uva e Vinho, reconhece a importância
              do turismo como um grande impulsionador do desenvolvimento econômico da cidade.
              Com o objetivo de apoiar o trade turístico e os players que atuam indiretamente no setor,
              foi criado o Credi Turismo Caxias, voltado, principalmente ao micro e pequeno empresário.
            </p>
            <p>
              O Credi Turismo Caxias visa facilitar o acesso ao crédito, fornecer
              capacitações e estimular toda a cadeia do turismo local. Através
              desse programa, os empreendedores terão a oportunidade de
              fortalecer seus negócios e contribuir para o crescimento do setor
              turístico em Caxias do Sul.
            </p>
            <p>
              O programa conta com uma linha de crédito assistido, fruto da união
              entre a Prefeitura de Caxias do Sul, Sebrae, Badesul, Sicredi Pioneira,
              Cresol e a RS Garanti. Essa parceria busca promover um ambiente favorável
              ao desenvolvimento do trade turístico, oferecendo suporte financeiro e
              conhecimento necessário para o sucesso dos empreendimentos.
            </p>
            <p>
              Caxias do Sul conta com um grande potencial para investimentos nos diferentes
              segmentos turísticos, principalmente pela sua infraestrutura. Com o apoio do
              Credi Turismo Caxias, os empreendedores locais poderão impulsionar a economia,
              gerar empregos e fortalecer o município.
            </p>
          </div>

          <div className="logos">
            <img src={img1}></img>
            <img src={novaImagem}></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BcxSectionA;
